import React from 'react';
import {
  ComponentPreview,
  DontDo,
  DontDoItem,
  List,
  Link,
  PageHero,
  PageNavigation,
  PageNavigationLink,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  PlatformTableRow,
  Section,
  SectionSubhead,
} from 'docComponents';
import { Icon, KeyboardShortcut } from 'hudl-uniform-ui-components';
import pageHeroData from '../../../data/pages/components.yml';

import styles from './styles/keyboardshortcuts.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Keyboard Shortcut"
      designCode
      subnav="components">
      <PageHero heroData={pageHeroData} tierTwo="Keyboard Shortcut" />
      <PageNavigation>
        <PageNavigationLink>Style</PageNavigationLink>
        <PageNavigationLink>Functions</PageNavigationLink>
        <PageNavigationLink>Content</PageNavigationLink>
        <PageNavigationLink>Mobile</PageNavigationLink>
        <PageNavigationLink>Usage</PageNavigationLink>
        <PageNavigationLink>Platform</PageNavigationLink>
      </PageNavigation>

      <Section title="Style">
        <Paragraph>
          The keyboard shortcut and accompanying content should always appear as
          a table in-line, as a dialog or via icon label.
        </Paragraph>
        <ComponentPreview name="keyboardStyle">
          <KeyboardShortcut>shift</KeyboardShortcut>
          <span className="uni-margin--half--horiz">+</span>
          <KeyboardShortcut>h</KeyboardShortcut>
        </ComponentPreview>
      </Section>

      <Section title="Functions">
        <Paragraph>
          If several shortcuts are available but real estate is limited, the
          shortcuts can be presented as a dialog triggered by the keyboard icon.
        </Paragraph>
        <ComponentPreview name="keyboardFunctions" layout="default">
          <div className={styles.kbdIconContainer}>
            <Icon size="large" type="keyboardshortcuts" />
            <div className={'uni-bg--level1 ' + styles.kbdContainer}>
              <div className={styles.kbdRow}>
                <div className={styles.kbdShortcut}>
                  <KeyboardShortcut>a</KeyboardShortcut>
                </div>
                <div className={styles.kbdDesc}>Backward 5 seconds</div>
              </div>
              <div className={styles.kbdRow}>
                <div className={styles.kbdShortcut}>
                  <KeyboardShortcut>f</KeyboardShortcut>
                </div>
                <div className={styles.kbdDesc}>Forward 5 seconds</div>
              </div>
              <div className={styles.kbdRow}>
                <div className={styles.kbdShortcut}>
                  <KeyboardShortcut>s</KeyboardShortcut>
                </div>
                <div className={styles.kbdDesc}>Back 1 frame</div>
              </div>
              <div className={styles.kbdRow}>
                <div className={styles.kbdShortcut}>
                  <KeyboardShortcut>d</KeyboardShortcut>
                </div>
                <div className={styles.kbdDesc}>Forward 1 frame</div>
              </div>
              <div className={styles.kbdRow}>
                <div className={styles.kbdShortcut}>
                  <KeyboardShortcut>space</KeyboardShortcut>
                </div>
                <div className={styles.kbdDesc}>Play/Pause</div>
              </div>
            </div>
          </div>
        </ComponentPreview>
      </Section>

      <Section title="Content">
        <Paragraph>
          Text options within the keyboard shortcut are limited. Case and
          punctuation depend entirely on what purpose the text serves.
        </Paragraph>
        <SectionSubhead>Keys</SectionSubhead>
        <Paragraph>
          All shortcuts should do their best to match what appears on the user’s
          keyboard.
        </Paragraph>
        <List type="unordered">
          <li>All single letters A-Z are uppercase.</li>
          <li>
            For non-letter keys such as <strong>enter</strong>,{' '}
            <strong>esc</strong> and <strong>shift</strong>, stick to lowercase.
          </li>
          <li>Use the arrow symbol as opposed to spelling things out.</li>
          <li>Same goes for numbers—numerals (1, 2, 3) are perfect.</li>
        </List>

        <SectionSubhead>Modifiers</SectionSubhead>
        <Paragraph>
          The only punctuation you should need is the <strong>+</strong> to
          indicate a <em>combination</em> of keys will activate the shortcut.
        </Paragraph>
        <div className="u-text-alignment--center uni-margin--two--vert">
          <KeyboardShortcut>shift</KeyboardShortcut>
          <span className="uni-margin--half--horiz">+</span>
          <KeyboardShortcut>h</KeyboardShortcut>
        </div>
        <Paragraph>
          For a sequence of keys where one must follow the other, spell out{' '}
          <strong>then</strong>. Stick to lowercase to match the non-letter
          keys.
        </Paragraph>
        <div className="u-text-alignment--center uni-margin--two--vert">
          <KeyboardShortcut>f</KeyboardShortcut>
          <span className="uni-margin--half--horiz">then</span>
          <KeyboardShortcut>w</KeyboardShortcut>
        </div>
        <Paragraph>
          If two different keys can execute the same action, or the shortcut
          itself may look different on the user’s keyboard, insert{' '}
          <strong>or</strong>.
        </Paragraph>
        <div className="u-text-alignment--center uni-margin--two--vert">
          <KeyboardShortcut>alt</KeyboardShortcut>
          <span className="uni-margin--half--horiz">or</span>
          <KeyboardShortcut>option</KeyboardShortcut>
        </div>

        <SectionSubhead>Results</SectionSubhead>
        <Paragraph>
          The action performed by a keyboard shortcut should not come off as a
          complete sentence. Use sentence case, but don’t give it closing
          punctuation.
        </Paragraph>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="get too detailed with the result."
            img="keyboardshortcut-result-dont"
          />
          <DontDoItem
            type="do"
            text="stick to the verb phrase."
            img="keyboardshortcut-result-do"
          />
        </DontDo>
      </Section>
      <Section title="Mobile">
        <Paragraph>
          Never hide important actions or information under a keyboard shortcut
          on mobile devices. Instead, display this in the interface in an easy
          to see place. Consider using a{' '}
          <Link href="/components/notifications/note/design">note</Link>.
        </Paragraph>
      </Section>
      <Section title="Usage">
        <SectionSubhead>Unique Functions</SectionSubhead>
        <Paragraph>
          Never use one key for multiple functions. Avoid the direction to
          “hold” a key for the sake of performing an action different than that
          of a tap.
        </Paragraph>
        <Paragraph>
          Instead, give each action its own key or combination of keys. We never
          know how quickly or slowly a user may interact with their keyboard.
        </Paragraph>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="include any modifier not related to sequence."
            img="keyboardshortcut-function-dont"
          />
          <DontDoItem
            type="do"
            text="assign a unique function its own key."
            img="keyboardshortcut-function-do"
          />
        </DontDo>
        <SectionSubhead>Container</SectionSubhead>
        <Paragraph>
          Always build the shortcut in a table or grid with everything (shortcut
          and result) left-aligned. For recommended margins within that grid, be
          sure to check our{' '}
          <Link href="/guidelines/space/design">space guidelines</Link>.
        </Paragraph>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="center the shortcut, no matter how few there may be."
            img="keyboardshortcut-container-dont"
          />
          <DontDoItem
            type="do"
            text="use a grid to make spacing easier."
            img="keyboardshortcut-container-do"
          />
        </DontDo>
        <SectionSubhead>Icon Label</SectionSubhead>
        <Paragraph>
          A keyboard shortcut displayed via{' '}
          <Link href="/components/icon/design#Labels">icon label</Link> should
          always appear in parentheses.
        </Paragraph>
        <Paragraph>
          Place it at the end of the label itself, <em>following</em> the action
          they’d perform. (The action is required. A shortcut should never
          appear on its own!)
        </Paragraph>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="provide a shortcut with zero context."
            img="keyboardshortcut-iconlabel-dont"
          />
          <DontDoItem
            type="do"
            text="list the icon’s purpose first."
            img="keyboardshortcut-iconlabel-do"
          />
        </DontDo>
        <SectionSubhead>Organization</SectionSubhead>
        <Paragraph>
          Use logic to group related shortcuts. Anything related to playback
          should appear together, as should tags, highlights, page controls,
          etc.
        </Paragraph>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="use a random system to organize shortcuts."
            img="keyboardshortcut-organization-dont"
          />
          <DontDoItem
            type="do"
            text="use headings to make large tables easier to navigate."
            img="keyboardshortcut-organization-do"
          />
        </DontDo>
      </Section>

      <Section title="Platform">
        <PlatformTable footerMessage="No guidelines exist for Windows (yet).">
          <PlatformTableRow platform="web" />
          <PlatformTableRow platform="apple">
            <List>
              <li>
                Follow{' '}
                <Link href="https://developer.apple.com/design/human-interface-guidelines/macos/user-interaction/keyboard/">
                  HIG
                </Link>{' '}
                for macOS.
              </li>
            </List>
          </PlatformTableRow>
          <PlatformTableRow platform="android" />
        </PlatformTable>
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;
